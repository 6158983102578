import React from 'react'
import { Link } from 'gatsby'
import LogoSmall from '../../images/svgs/swenden-logo-small.svg'
import IconSocialXing from '../../images/svgs/icon_social_xing.svg'
import IconSocialLinkedin from '../../images/svgs/icon_social_linkedin.svg'
import IconSocialFacebook from '../../images/svgs/icon_social_facebook.svg'
import IconSocialInstagram from '../../images/svgs/icon_social_instagram.svg'
import './Footer.css'

const Footer = (props) => {

  return (
    <footer className='flex w-full dark-section pt-16 pb-8 px-6 justify-center'>
        <div className='flex w-full flex-wrap max-w-6xl'>
            <div className='flex w-full flex-wrap sm:mb-8 md:mb-0 md:w-4/12 lg:w-3/12'>
                <Link to='/'>
                    <LogoSmall className='fill-current w-12' />
                </Link>
                <span className="logo-claim text-white pl-4">Digital Product<br/>Development</span>
                <div className="flex w-full social-icons mt-8 mb-8 md:mb-0 md:mt-24">
                </div>
            </div>
            <div className='flex w-full flex-wrap text-white -mt-2 sm:mb-8 md:mb-0 md:w-4/12 lg:w-5/12 contact-info'>
            <p className="w-full">swenden GmbH i.L.<br/>
            Mühlenstraße 8 A<br/>
            14167 Berlin</p>
            <p className="w-full">
            Telefon: 030 / 555 710 60<br/>
            E-Mail: kontakt@swenden.de<br/></p>
            </div>
            <div className='flex w-full flex-wrap sm:w-12/12 md:w-4/12 justify-end'>
                <div className='flex w-full flex-wrap sm:w-12/12 md:w-6/12 lg:w-4/12 text-white'>
                    <ul>
                        <li><Link to='../impressum' className='transition' activeStyle={{ fontWeight: 'bold', borderBottom: 'solid 2px black' }}>Impressum</Link></li>
                        <li><Link to='../datenschutz' className='transition' activeStyle={{ fontWeight: 'bold', borderBottom: 'solid 2px black' }}>Datenschutz</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
