import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import './Navigation.css'
import Button from '../Button/Button'
import Logo from '../../images/svgs/swenden-logo.svg'
import LogoSmall from '../../images/svgs/swenden-logo-small.svg'

const Navigation = ({ location }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isCompact, setIsCompact] = useState(false)
  const [isScroll, setIsScroll] = useState(false)

  const bgColor = () => {
    switch(location) {
      case '/test':
        return 'bg-grey'

      default:
        return 'darkgrey'
    }
  }

  const data = useStaticQuery(graphql`

  query LabelsMenuItems {
    contentfulTopMenuItems(slug: {eq: "topmenu"}) {
      labelAboutpage
      labelButtonStartProject
      labelPortfoliopage
      labelStartpage
    }
  }
  `)

  function onScroll() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    if (document.body.scrollTop > 240 || document.documentElement.scrollTop > 240) {
      setIsCompact(true);
    } else {
      setIsCompact(false);
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
  }, [])


  return (
   <>
   <nav className={`flex items-center justify-center w-full ${isScroll ? 'py-2': 'py-4'} px-4 z-50 bg-darkgrey ${isScroll ? 'prepair-nav': ''} ${isCompact ? 'compact-nav': ''}`}>
      <div className='flex flex-col z-9999 lg:flex-row flex-wrap items-center justify-between max-w-6xl w-full'>
        <div className='flex items-center justify-between w-full lg:w-auto z-20'>
          <Link to='/'>
              {isCompact ? <LogoSmall className="w-10 h-auto"/> : <Logo className='fill-current w-32'/> }
          </Link>
          <div className='flex items-center'>
            <div className='lg:hidden cursor-pointer scale-menu-icon' onClick={() => toggleExpansion(!isExpanded)}>&#8801;</div>
          </div>
        </div>
        <div className={`${isExpanded ? 'nav-show' : 'nav-hide'} bg-${bgColor()} nav-content w-full lg:w-auto pb-6 lg:pb-0 z-10 flex absolute lg:static transition-slow flex-col my-0 lg:m-0 lg:flex lg:flex-row items-center justify-between`}>
          <Link to='/impressum' className='nav-link transition block md:hidden' activeStyle={{borderBottom: 'solid 1px #ffffff' }}>Impressum</Link>
        </div>
      </div>
    </nav>
    {isScroll ? <div className="nav-replacement"></div> : ''}
    </>
  )
}

export default Navigation
