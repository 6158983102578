import React from 'react'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

import Navigation from '../Navigation/Navigation'
import ContactFormFooter from '../ContactFormFooter/ContactFormFooter'
import Footer from '../Footer/Footer'
import './Layout.css'
import '../../styles/global.css'
import favicon from '../../images/favicon.png'
import Helmet from 'react-helmet'

const Layout = ({ children }) => {
  return (
      <Location>
        {({ location }) => (
          <>
            <Navigation location={location.pathname} />
            <Helmet>
              <link rel="icon" href={favicon} />
            </Helmet>
            <div className='flex flex-col flex-wrap items-center w-full'>
              <main className='w-full flex flex-col flex-wrap'>{children}</main>
            </div>
            <Footer />
          </>
        )}
      </Location>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
